import { useTranslation } from 'react-i18next';
import HeaderInvoice from './HeaderInvoice';
import {InvoiceData} from '../../../domain/InvoiceData';
import InvoiceDetailsGrid from './InvoiceDetailsGrid';
import Heading from '../../Common/Heading';
import BackButton from '../../Common/BackButton';
import usePaginationContext from "../../../hooks/context/use-pagination-context";
import {InvoiceContext, useInvoiceContextData} from "../../../hooks/context/use-invoice-context";
import InvoiceLogs from "./InvoiceLogs";
import {FormHelperText} from "@mui/material";
import React from "react";


interface Props{
    invoiceData: InvoiceData;
}
const Invoice = ({invoiceData}: Props) => {
    const {t} = useTranslation();
    const invoiceContextData = useInvoiceContextData({invoiceData:invoiceData})
    const invoice = invoiceContextData.invoice;
    const paginationContextData = usePaginationContext();

    return (
        <InvoiceContext.Provider value={invoiceContextData}>
            <BackButton to=".." />
            <HeaderInvoice/>
            {invoice.state.id == 2 &&
                <FormHelperText sx={{ color: '#ff7500' }}>
                    {t('pending accumulation invoice details payment days warning')}
                </FormHelperText>
            }
            {invoice.state.id == 1 &&
                <FormHelperText sx={{ color: '#ff7500' }}>
                    {t('pending review invoice details payment days warning')}
                </FormHelperText>
            }
            <div className="cc-card cc-card--invoice">
                <Heading title={t('customer lines')} />
                <InvoiceDetailsGrid
                    invoiceDetails={invoice.invoice_details.filter((invDet) => invDet.type.id == 2)}
                    customTypes={[
                        { id: 2, label: 'Customer' }
                    ]}
                    allowEditingActions={true}
                    paginationSettings={
                        {
                            key: 'invoice-details-customer',
                            rowsPerPage: paginationContextData.getRowsPerPage('invoice-details-customer') ?? 10,
                            rowsPerPageOptions: [10, 20, 30, 40, 50]
                        }
                    }
                />
            </div>
            <div className="cc-card cc-card--invoice">
                <Heading title={t('partner lines')} />
                <InvoiceDetailsGrid
                    invoiceDetails={invoice.invoice_details.filter((invDet) => invDet.type.id == 1)}
                    allowEditingActions={false}
                    paginationSettings={
                        {
                            key: 'invoice-details-backoffice',
                            rowsPerPage: paginationContextData.getRowsPerPage('invoice-details-backoffice') ?? 10,
                            rowsPerPageOptions: [10, 20, 30, 40, 50]
                        }
                    }
                />
            </div>
            <div className="cc-card cc-card--invoice">
                <Heading title={t('change log')} />
                <InvoiceLogs logs={invoice.invoice_logs}/>
            </div>
        </InvoiceContext.Provider>
    );
};
export default Invoice